import { Component, HostListener, OnInit} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { IsLoadingService } from '@service-work/is-loading';
import { CoreService } from '../../../core.service';
import { AuthService } from '../../../../modules/auth/auth.service';

import { User } from '../../../../shared/models/user';
import { Area } from '../../../../shared/models/area';
import { ErrorHandler } from '../../../../shared/http/responses/error-handler';

@Component({
    selector:    'app-side-menu',
    templateUrl: './side-menu.component.html'
})

export class SideMenuComponent implements OnInit{
    public user: User = new User();
    public areas: Area[] | number = [];

    constructor(private router: Router,
                private authService: AuthService,
                public layoutService: CoreService) {
        this.onResize();
        this.user = this.authService.getAuthSnapshot();
        //this.areas = this.layoutService.getAreasSnapshot();

        // Required to remount menu on child route navigation
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;

        /*this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // Trick the Router into believing it's last link wasn't previously loaded
                this.router.navigated = false;
            }
        });*/
    }

    async ngOnInit() {
        this.user = await this.authService.getAuth();
        this.layoutService.getAreas().then(result => {
            this.areas = (!result.length) ? -1 : result;
        });
    }

    toggle(el) {
        if (el.classList.contains('portal-navigation-list__item--open')) {
            el.classList.remove('portal-navigation-list__item--open');
        } else {
            el.classList.add('portal-navigation-list__item--open');
        }
    }

    child_active(el) {
        const list = el.nextElementSibling.children;

        for (let i = 0; i < list.length; i++) {
            if (list[i].classList.contains('mdc-list-item--activated')) {
                return true;
            }
        }

        return false;
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        if (window.innerWidth > 1125 && !this.layoutService.menu) {
            this.layoutService.toggleMenu();
        }

        if (window.innerWidth < 1125 && this.layoutService.menu) {
            this.layoutService.toggleMenu();
        }
    }
}
