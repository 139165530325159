import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { User } from '../../../../shared/models/user';
import { CoreService } from '../../../core.service';
import { AuthService } from '../../../../modules/auth/auth.service';

declare var require: any;
const CryptoJS = require('crypto-js');

@Component({
    selector: 'app-top-toolbar',
    host: {
        '(document:click)': 'functionClick($event.path)',
      },
    templateUrl: './top-toolbar.component.html',
    animations: [
        trigger('show_profile_modal', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate(200, style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate(300, style({ opacity: 0 }))
            ])
        ])
    ]
})


export class TopToolbarComponent implements OnInit {

    public user: User = new User();
    public insertionsData: Array<any> = new Array<any>();

    public show_profile_modal = false;
    public show_alert_modal = false;

    constructor(public layoutService: CoreService,
        private authService: AuthService,
        private elRef: ElementRef) {
        this.user = this.authService.getAuthSnapshot();
    }

    async ngOnInit() {
        this.user = await this.authService.getAuth();
    }

    toggleProfileModal() {
        this.show_profile_modal = !this.show_profile_modal;
        this.show_alert_modal = false;
    }

    functionClick(evt){
        const elementRefInPath = evt.find(e => e === this.elRef.nativeElement);
        if (!elementRefInPath){
            this.show_profile_modal = false;
        }
    }

}
