import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Subarea } from '../models/subarea';

@Injectable(<any>{
    providedIn: 'root'
})

export class SubareaService {
    public constructor(private http: HttpClient,
    private router: Router) {

    }

    public all(area: string = null) {
        const endpoint = '/subareas';
        let params = {};

        if(area){
            params['area'] = area;
        }

        return this.http.get(endpoint, {params: params}).pipe(
            map((response: Subarea[]) => {
                if(!response) return [];

                return response.map(subarea => {
                    return new Subarea().deserialize(subarea);
                });
            }),
            catchError((response: any, caught) => {
                if(response.status == 422 || response.status == 404){
                    return this.router.navigate(['/error/404']);
                }

                return throwError(response);
            })
        );
    }
}
