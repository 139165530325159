import {Component} from '@angular/core';
import {CoreService} from '../../../core.service';
import {environment} from '../../../../../environments/environment';
import swal from 'sweetalert2';

declare var document: any;

@Component({
    selector:    'app-footer',
    templateUrl: './footer.component.html'
})

export class FooterComponent {
    private api_url: string = environment.apiEndpoint;
    public version: string = environment.version;
    public api_version = '';
    public year;

    constructor(private layoutService: CoreService) {
        this.year = new Date();

        layoutService.getApiVersion().subscribe((version: string) => {
            this.api_version = version;
        });
    }


    showVersion() {
        swal.fire({
            title: 'Versão',
            html:              '<div style="text-align: left">' +
                               '<strong>API URL: </strong>' + this.api_url + ' <br>' +
                               '<strong>SITE: </strong>' + this.version + ' <br>' +
                               '<strong>API: </strong>' + this.api_version + ' <br></div>',
            type:              'info',
            showCancelButton:  false,
            confirmButtonText: 'Continuar!'
            // cancelButtonText: 'Não, cancelar!'

        });

    }
}
