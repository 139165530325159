import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { User } from '../../shared/models/user';
import { Pagination } from '../../shared/models/pagination';

import { ErrorHandler } from '../../shared/http/responses/error-handler';

import * as httpBuildQuery from 'http-build-query';

@Injectable(<any>{
    providedIn: 'root'
})

export class UserService {
    public constructor(private http: HttpClient,
                       private router: Router) {
    }

    find(id: number) {
        const url = '/users/' + id;

        return this.http.get<User>(url).pipe(
            map((response) => {
                return new User().deserialize(response);
            }),
            catchError((response, caught) => {
                if (response.status === 404) {
                    return this.router.navigate(['/error/404']);
                }

                new ErrorHandler(response).show();
                return throwError(response);
            })
        );
    }

    /*all() {
        const url = '/users/all';

        return this.http.get<Array<User>>(url);
    }*/

    paginate(page: number = 1, search: any, sort: any) {
        const query = httpBuildQuery({'page': page, 'search': search, 'sort': sort});
        const url = '/users?' + query;

        return this.http.get<Pagination>(url).pipe(
            map((response) => {
                let pagination = new Pagination().deserialize(response);
                pagination.data = pagination.data.map(user => new User().deserialize(user));
                return pagination;
            }),
            catchError((response, caught) => {
                new ErrorHandler(response).show();
                return throwError(response);
            })
        )
    }

    create(user: User) {
        const url = '/users';

        return this.http.post<User>(url, user).pipe(
            map((response) => {
                return new User().deserialize(response);
            }),
            catchError((response, caught) => {
                new ErrorHandler(response).show();
                return throwError(response);
            })
        );
    }

    update(user: User, send_email: boolean = false) {
        const query = httpBuildQuery({'send_email': send_email});
        const url = '/users/' + user.id + '?' + query;

        return this.http.put(url, user).pipe(
            map((response) => {
                return response;
            }),
            catchError((response, caught) => {
                new ErrorHandler(response).show();
                return throwError(response);
            })
        );
    }

    updatePassword(user: User) {
        const url = '/users/password';

        return this.http.put(url, user).pipe(
            map((response) => {
                return response;
            }),
            catchError((response, caught) => {
                new ErrorHandler(response).show();
                return throwError(response);
            })
        )
    }

    syncGroups(userId: number, groups: []) {
        const url = '/users/' + userId + '/groups';

        return this.http.put(url, {'groups': groups}).pipe(
            map((response) => {
                return response;
            }),
            catchError((response, caught) => {
                new ErrorHandler(response).show();
                return throwError(response);
            })
        );
    }

    destroy(id: number) {
        const url = '/users/' + id;

        return this.http.delete(url).pipe(
            map((response) => {
                return response;
            }),
            catchError((response, caught) => {
                new ErrorHandler(response).show();
                return throwError(response);
            })
        );
    }
}
