import { Deserializable } from "./contracts/deserializable";

export class Pagination {
    current_page = 1;

    data: Array<any> = [];

    first_page_url = '';

    from = 1;

    last_page = 1;

    last_page_url = '';

    next_page_url: string | null = null;

    path = '';

    per_page = 10;

    prev_page_url: string | null = null;

    to = 2;

    total = 2;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}
