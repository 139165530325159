import { Component, OnInit} from '@angular/core';
import { AuthService } from '../../../modules/auth/auth.service';
import { User } from '../../../shared/models/user';

@Component({
    selector:    'app-layout-classic',
    templateUrl: './classic.component.html'
})

export class LayoutClassicComponent implements OnInit {
    public user: User;

    constructor(private authService: AuthService) {
    }

    async ngOnInit() {
        this.user = await this.authService.getAuth();
    }
}
