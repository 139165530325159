import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IsLoadingService } from '@service-work/is-loading';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs';

import { AreaService } from '../shared/services/area.service'
import { SubareaService } from '../shared/services/subarea.service';

import { Area } from '../shared/models/area';
import { Subarea } from '../shared/models/subarea';

import * as mdc from 'material-components-web';

declare var $: any;

@Injectable(<any>{
    providedIn: 'root'
})

export class CoreService {
    public isLoading: Observable<boolean>;
    public toggleMenu$: Subject<boolean> = new Subject();
    public menu = true;

    private _loadAreas;
    private _loadSubareas;

    constructor(private http: HttpClient,
                private isLoadingService: IsLoadingService,
                private areaService: AreaService,
                private SubareaService: SubareaService) {
        this.isLoading = this.isLoadingService.isLoading$();
        mdc.autoInit();
    }

    private loadAreas(): Promise<Area[]> {
        const self = this;
        return new Promise((resolve) => {
            this.isLoadingService.add(
                this.areaService.all().subscribe({
                    next(data){
                        self.setAreasSnapshot(data);
                        resolve(data);
                    }
                })
            );
        });
    }

    private setAreasSnapshot(areas: Area[]) {
        localStorage.setItem('areas', JSON.stringify(areas));
    }

    public getAreasSnapshot() {
        let areas = JSON.parse(localStorage.getItem('areas'));

        if(areas){
            return areas.map(area => {
                return new Area().deserialize(area);
            });
        }

        return [];
    }

    private loadSubareas(): Promise<Subarea[]> {
        const self = this;
        return new Promise((resolve) => {
            this.isLoadingService.add(
                this.SubareaService.all().subscribe({
                    next(data: Subarea[]){
                        resolve(data);
                    }
                })
            );
        });
    }

    reloadAreas(): void {
        this._loadAreas = this.loadAreas();
        this._loadSubareas = this.loadSubareas();
    }

    getAreas() {
        if(!this._loadAreas){
            this._loadAreas = this.loadAreas();
        }

        return this._loadAreas;
    }

    getSubareas(area: string = null, prop: string = 'slug') {
        if(!this._loadSubareas){
            this._loadSubareas = this.loadSubareas();
        }

        return this._loadSubareas.then((subareas: Subarea[]) => {
            if(area){
                return subareas.filter((subarea) => {
                    return subarea.area[prop] == area;
                })
            }

            return subareas;
        });
    }

    reload(): void {
        this.reloadInputs();
        this.reloadSelects();
        this.reloadTooltips();
    }

    reloadTooltips(): void {
        $('[data-toggle="tooltip"]').tooltip();
    }

    reloadInputs(): void {
        const text_field = document.querySelectorAll('.mdc-text-field');
        for (let i = 0; i < text_field.length; i++) {
            new mdc.textField.MDCTextField(text_field[i]);
        }
    }

    reloadSelects(): void {
        const select_field = document.querySelectorAll('.mdc-select');

        for (let i = 0; i < select_field.length; i++) {
            new mdc.select.MDCSelect(select_field[i]);
        }
    }

    toggleMenu() {
        this.menu = !this.menu;

        setTimeout(() => {
            // Wait until animation is over
            this.toggleMenu$.next(this.menu);
        }, 500);
    }

    getApiVersion() {
        const url = '/version';
        return this.http.get<string>(url);
    }
}
