import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Area } from '../models/area';
import { ErrorHandler } from '../http/responses/error-handler';

@Injectable(<any>{
    providedIn: 'root'
})

export class AreaService {
    public constructor(private http: HttpClient) {

    }

    public all() {
        const endpoint = '/areas';

        return this.http.get(endpoint).pipe(
            map((response: Area[]) => {
                if(!response) return [];

                return response.map(area => {
                    return new Area().deserialize(area);
                });
            }),
            catchError((response, caught) => {
                new ErrorHandler(response).show();
                return throwError(response);
            })
        );
    }
}
