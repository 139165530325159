import { Deserializable } from "./contracts/deserializable";

export class Group implements Deserializable {
    id: number;

    name: string;

    area_id: number;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }
}
