import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { IsLoadingService } from '@service-work/is-loading';
import { AuthService } from '../auth.service';

@Component({
    selector:    'app-logout',
    templateUrl: './logout.component.html'
})

export class LogoutComponent {
    public constructor(private router: Router,
                       private authService: AuthService,
                       private isLoadingService: IsLoadingService) {
    }

    public logout() {
        this.isLoadingService.add(
            this.authService.logout().subscribe(() => {
                localStorage.removeItem('token');
                this.router.navigate(['/auth/login']);
            })
        );
    }
}
