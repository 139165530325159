import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../modules/auth/auth.service';
import { Observable } from 'rxjs/internal/Observable';

@Injectable(<any>{
    providedIn: 'root'
})

export class RedirectIfNotAdminGuard implements CanActivate {
    constructor(private router: Router,
                private authService: AuthService) {
    }

    public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.authService.getAuth(true).then((user) => {
            if(!user.isAdmin()){
                this.router.navigate(['/error/403']);
                return false;
            }

            return true;
        });
    }
}
