import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { APIInterceptor } from './shared/http/interceptors/api-interceptor';
import { RedirectIfAuthenticatedGuard } from './shared/middlewares/redirect-if-authenticated.guard';
import { RedirectIfNotAuthenticatedGuard } from './shared/middlewares/redirect-if-not-authenticated.guard';
import { RedirectIfNotAdminGuard } from './shared/middlewares/redirect-if-not-admin.guard';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        CoreModule,
        ToastrModule.forRoot({
            progressBar: true,
            enableHtml: true
        }),
    ],
    providers: [
        RedirectIfAuthenticatedGuard,
        RedirectIfNotAuthenticatedGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: APIInterceptor,
            multi: true
        },
        RedirectIfNotAdminGuard
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
}
