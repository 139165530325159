import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../modules/auth/auth.service';
import { Observable } from 'rxjs/internal/Observable';

@Injectable(<any>{
    providedIn: 'root'
})

export class RedirectIfNotAuthenticatedGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthService) {
    }

    public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const token = localStorage.getItem('token');

        if (!token) {
            this.router.navigate(['/login']);
            return true;
        }

        this.authService.checkAuth(token).subscribe(response => {
            if (!response) {
                localStorage.removeItem('token');
                this.router.navigate(['/auth/logout']);
            }
        });

        return true;
    }
}
