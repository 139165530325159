import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { User } from '../../shared/models/user';
import { UserService } from '../users/user.service';
import { ErrorHandler } from '../../shared/http/responses/error-handler';

import { environment } from '../../../environments/environment';

declare var require: any;
const CryptoJS = require('crypto-js');

@Injectable(<any>{
    providedIn: 'root'
})

export class AuthService {
    private _auth: Promise<User>;

    public constructor(private http: HttpClient,
                       private userService: UserService) {
    }

    private loadAuth(): Promise<User> {
        let userId = this.getAuthSnapshot().id;
        return this.userService.find(userId).toPromise().then((user: User) => {
            this.setAuthSnapshot(user);
            return user;
        });
    }

    public reloadAuth(): void {
        this._auth = this.loadAuth();
    }

    private _getAuth(reload: boolean = false): Promise<User> {
        if(!this._auth || reload === true){
            this._auth = this.loadAuth();
        }

        return this._auth;
    }

    public async getAuth(reload: boolean = false) {
        return <User>await this._getAuth(reload);
    }

    public hasAuth() {
        return !!localStorage.getItem('token');
    }

    private setAuthSnapshot(user: User): void {
        if(user['token']) localStorage.setItem('token', user.token);
        localStorage.setItem('user', CryptoJS.AES.encrypt(JSON.stringify(user), environment.secretKey));
    }

    public getAuthSnapshot(): User {
        const bytes = CryptoJS.AES.decrypt(localStorage.getItem('user').toString(), environment.secretKey);
        return new User().deserialize(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
    }

    login(user) {
        const url = '/auth/login';

        return this.http.post(url, user).pipe(
            map((response) => {
                if (!response) {
                    throw new Error('Usuário não encontrado ou cadastro pendente de ativação.');
                }

                const user = new User().deserialize(response);
                this.setAuthSnapshot(user);
                return user;
            }),
            catchError((response, caught) => {
                new ErrorHandler(response).show();
                return throwError(response);
            })
        );
    }

    logout() {
        const url = '/auth/logout';
        return this.http.get(url);
    }

    register(user) {
        const url = '/auth/register';
        return this.http.post(url, user);
    }

    forgotPassword(user) {
        const url = '/auth/forgot-password';
        return this.http.post(url, user);
    }

    /*recoverPassword(user) {
        const url = '/auth/recover-password';

        return this.http.post(url, user).subscribe(response => {
            this.router.navigate(['/auth/login']);
        },
        error => {
          alert(Object.values(error.error.errors)[0][0]);
        });
    }*/

    checkAuth(token) {
        const url = '/auth/check-auth?token=' + token;

        return this.http.get<User | boolean>(url);
    }
}
