import { Deserializable } from "./contracts/deserializable";
import { Group } from "./group";

export class User implements Deserializable {
    id: number;

    name: string;

    email: string;

    password: string;

    password_confirmation: string;

    network_logion: string;

    cpf: string;

    network_login: string;

    office: string;

    phone: string;

    token: string;

    status: boolean;

    send_email: boolean = false;

    groups: Group[] = [];

    created_at: string;

    updated_at: string;

    deleted_at: string;

    deserialize(input: any) {
        Object.assign(this, input);

        if(input.groups){
            this.groups = input.groups.map(group => {
                return new Group().deserialize(group);
            });
        }

        return this;
    }

    clearPassword(): User {
        this.password = null;
        this.password_confirmation = null;
        return this;
    }

    isAdmin(): boolean {
        if(this.groups.length){
            return this.groups.filter(group => group.name === 'Admin').length ? true : false;
        }

        return false;
    }

    hasGroupIn(supId: number): boolean {
        if(this.groups.length){
            return this.groups.filter(group => group.area_id === supId).length ? true : false;
        }

        return false;
    }

    hasGroup(groupId: number): boolean {
        if(this.groups.length){
            return this.groups.filter(group => group.id === groupId).length ? true : false;
        }

        return false;
    }
}
