import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutClassicComponent } from './core/layout/layout-classic/classic.component';
import { LayoutClearComponent } from './core/layout/layout-clear/clear.component';
import { RedirectIfAuthenticatedGuard } from './shared/middlewares/redirect-if-authenticated.guard';
import { RedirectIfNotAuthenticatedGuard } from './shared/middlewares/redirect-if-not-authenticated.guard';
import { RedirectIfNotAdminGuard } from './shared/middlewares/redirect-if-not-admin.guard';

const routes: Routes = [
    { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
    { path: 'login', redirectTo: '/auth/login' },

    {
        path: 'auth',
        data: { base: true },
        component: LayoutClearComponent,
        canActivate: [RedirectIfAuthenticatedGuard],
        children: [
            { path: '', loadChildren: './modules/auth/auth.module#AuthModule' }
        ]
    },
    {
        path: 'dashboard',
        data: { base: true },
        component: LayoutClassicComponent,
        canActivate: [RedirectIfNotAuthenticatedGuard],
        children: [
            { path: '', loadChildren: './modules/dashboard/dashboard.module#DashboardModule' }
        ]
    },
    {
        path: 'profile',
        data: { base: true },
        component: LayoutClassicComponent,
        canActivate: [RedirectIfNotAuthenticatedGuard],
        children: [
            { path: '', loadChildren: './modules/profile/profile.module#ProfileModule' }
        ]
    },
    {
        path: 'users',
        data: { base: true },
        component: LayoutClassicComponent,
        canActivate: [RedirectIfNotAuthenticatedGuard, RedirectIfNotAdminGuard],
        children: [
            { path: '', loadChildren: './modules/users/user.module#UserModule' }
        ]
    },
    {
        path: 'indicators',
        data: { base: true },
        component: LayoutClassicComponent,
        canActivate: [RedirectIfNotAuthenticatedGuard],
        children: [
            { path: '', loadChildren: './modules/indicators/indicator.module#IndicatorModule' }
        ]
    },
    {
        path: 'error', data: { base: true }, component: LayoutClearComponent, children: [
            { path: '', loadChildren: './modules/errors/error.module#ErrorModule' }
        ]
    },

    { path: '**', redirectTo: '/error/404' }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(routes)
    ],
    exports: [
        RouterModule
    ]
})

export class AppRoutingModule {
}
