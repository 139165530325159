import { Deserializable } from "./contracts/deserializable";
import { Area } from "./area";

export class Subarea implements Deserializable {

    id: number;

    name: string;

    area: Area;

    deserialize(input: any) {
        Object.assign(this, input);
        this.area = new Area().deserialize(input.area);
        return this;
    }

}
