import { Deserializable } from "./contracts/deserializable";

export class Area implements Deserializable {

    id: number;

    name: string;

    slug: string;

    deserialize(input: any) {
        Object.assign(this, input);
        return this;
    }

}
