import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { IsLoadingService } from '@service-work/is-loading';
import { CoreService } from './core/core.service';
import { AuthService } from './modules/auth/auth.service';
import { filter } from 'rxjs/operators';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})

export class AppComponent implements OnInit {
    constructor(private router: Router,
                private isLoadingService: IsLoadingService,
                private authService: AuthService,
                private coreService: CoreService) {
    }

    ngOnInit() {
        this.router.events.pipe(
            filter(event =>
                event instanceof NavigationStart ||
                event instanceof NavigationEnd ||
                event instanceof NavigationCancel ||
                event instanceof NavigationError
            ),
        ).subscribe((event) => {
            // If it's the start of navigation
            if (event instanceof NavigationStart) {
                // Add a loading indicator
                this.isLoadingService.add();

                if(this.authService.hasAuth()){
                    // Always update authenticated user and groups
                    this.authService.reloadAuth();
                    // Always update areas and subareas
                    this.coreService.reloadAreas();
                }

                return;
            }

            // Else navigation has ended, so remove a loading indicator
            this.isLoadingService.remove();
        });
    }
}
