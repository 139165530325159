import swal from 'sweetalert2';

export class ErrorHandler {
    public error: any;

    public title = 'Atenção!';

    public error_type: any = 'error';

    public constructor(error: any = false) {
        if (error) {
            this.error = error;
        }
    }

    public show(message: string = '') {
        if (message) {
            return swal.fire(this.title, message, this.error_type);
        }

        if (this.error.status === 401) {
            return swal.fire(this.title, 'Você não tem permissão para acessar esta página e/ou ação.', this.error_type);
        }

        if (this.error.status === 403) {

            if (this.error['error']['errors']) {
                let stack = this.error.error.errors;
                return swal.fire(this.title, `${stack.description} ${stack.help}`, this.error_type);
            }
        }

        if (this.error.status === 405) {
            return swal.fire(this.title, 'Método não autorizado para seu usuário.', this.error_type);
        }

        if (this.error.status === 429) {
            return swal.fire(this.title, 'Muitas requisições realizadas, aguarde um momento.', this.error_type);
        }

        if(this.error.status === 422) {
            if(this.error['error']['errors']){
                let stack = this.error.error.errors;

                if(Array.isArray(stack)){
                    let message = this.formatMessage(stack);
                    return swal.fire(this.title, message, this.error_type);
                }

                let prop = Object.keys(stack)[0];
                return swal.fire(this.title, stack[prop][0], this.error_type);
            }
        }

        return swal.fire(this.title, 'Erro interno, contate o administrador.', this.error_type);
    }

    private formatMessage(errors: any[]){
        let message = '';
        errors.forEach(err => {message += err.message + '<br>';});
        return message;
    }
}
